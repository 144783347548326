// Notifications.js
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getFCMToken } from "../../firebaseInit";
import { setToken } from "../../redux/Slice/User/user";

const Notifications = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    async function tokenFunc() {
      const data = await getFCMToken(setTokenFound);
      if (data) {
        dispatch(setToken(data));
      }
    }
    tokenFunc();
  }, []);

  return <></>;
};

export default Notifications;
