import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { postAuthReq } from "../../utils/apiHandlers";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const TwitterOauth = () => {
  const { userData } = useSelector((state) => state?.user || {});
  const navigate = useNavigate();
  useEffect(() => {
    const fetchTwitterToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      if (code && userData?.id) {
        try {
          const data = {
            userId: userData?.id,
            token: code
          };
          const res = await postAuthReq("/auth/get-twitter-oauth", data);

          const { status } = res;
          if (status) {
            toast.success("Connected Successfully");
            navigate("/rewards");
          } else {
            if (Array.isArray(res?.error?.message)) {
              toast.error(res?.error?.message[0]);
              toast.error(res?.error?.message);
            }
            toast.error(res?.error?.message);
            setTimeout(() => {
              navigate("/rewards");
            }, 500);
          }
        } catch (error) {
          console.error("Error exchanging token:", error);
        }
      }
    };

    fetchTwitterToken();
  }, [navigate, userData?.id]);

  return <></>;
};

export default TwitterOauth;
