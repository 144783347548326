import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import store from "./redux/store";
import { Provider } from "react-redux";
import { WagmiProvider } from "wagmi";
import { currentNetwork } from "./utils/customNetwork";
import { createAppKit } from "@reown/appkit/react";
import { WagmiAdapter } from "@reown/appkit-adapter-wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ReactGA from "react-ga4";
// 0. Setup queryClient
const queryClient = new QueryClient();

const projectId = process.env.REACT_APP_PROJECT_ID;

// 2. Create wagmiConfig
const metadata = {
  name:
    process.env.REACT_APP_NETWORK === "testnet" ? "MST Testnet" : "MST Mainnet",
  description:
    "MST Blockchain, built by visionary developers, embodies a relentless belief in the transformative power of decentralization.",
  url:
    process.env.REACT_APP_NETWORK === "testnet"
      ? "https://staging.mstvalidator.com/"
      : "https://mstvalidator.com/", // origin must match your domain & subdomain
  icons:
    process.env.REACT_APP_NETWORK === "testnet"
      ? ["https://staging.mstvalidator.com/images/logo/main.png"]
      : [
          "https://imagedelivery.net/_aTEfDRm7z3tKgu9JhfeKA/f6f79fda-3a73-4e9f-2e99-2d9ddc004100/sm"
        ]
};
const wagmiAdapter = new WagmiAdapter({
  networks: [currentNetwork],
  projectId
});
createAppKit({
  adapters: [wagmiAdapter],
  networks: [currentNetwork],
  metadata: metadata,
  projectId,
  features: {
    analytics: true,
    socials: false,
    email: false
  }
});

const container = document.getElementById("root");
const root = createRoot(container);
const TrackingId = process.env.REACT_APP_TRACKING_ID;
ReactGA.initialize(TrackingId);
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered: ", registration);
    })
    .catch((error) => {
      console.log("Service Worker registration failed: ", error);
    });
}

if (
  "serviceWorker" in navigator &&
  process.env.REACT_APP_NETWORK === "testnet"
) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js") // Remove the leading slash
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <WagmiProvider config={wagmiAdapter.wagmiConfig}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </WagmiProvider>
      </Router>
    </Provider>
  </React.StrictMode>
);
