import * as yup from "yup";
import { passwordRegex } from "./regex";

export const signUpValidation = yup.object().shape({
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
});

export const subAdminValidation = yup.object().shape({
  fullname: yup.string().required("Please enter you full name"),
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  password: yup
    .string()
    .required("Please enter password.")
    .matches(
      /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
      "Only accept One Uppercase and atleast one special characters and numbers."
    )
    .min(8, "Minimum 8 characters is required.")
});
export const addBuyersValidation = yup.object().shape({
  firstname: yup
    .string()
    .required("Please enter your first name")
    .max(50, "First Name length invalid"),
  lastname: yup
    .string()
    .required("Please enter your last name")
    .max(50, "Last Name length invalid"),
  address_line_1: yup
    .string()
    .required("Please enter your address")
    .min(3, "Address must be at least 3 characters long")
    .max(100, "Address must not exceed 100 characters"),
  // address2: yup.string().required('Please enter your address'),
  // country: yup.string().required('Please select country'),
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
    .max(100, "Email exceeds limit"),

  // mobile: yup.string().required("Mobile number is required"),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[1-9][0-9]*$/, "Mobile number should not start with 0")
    .min(10, "Mobile number must be at least 10 digits")
    .max(10, "Mobile number must not exceed 10 digits"),
  password: yup
    .string()
    .required("Please enter password")
    .matches(
      passwordRegex,
      "Password must be at least 8 characters including one uppercase letter, one special character, and alphanumeric characters"
    )
  // mobileVerificationCode: yup
  //   .string()
  //   .required('Please Enter Mobile Verification Code'),
  // password: yup
  //   .string()
  //   .required('Please enter password.')
  //   .matches(
  //     /^(?=.*[A-Z])(?=.*[~!@#$%^&*()/_=+[\]{}|;:,.<>?-])(?=.*[0-9])(?=.*[a-z]).{8,14}$/,
  //     'Only accept One Uppercase and atleast one special characters and numbers.',
  //   )
  //   .min(8, 'Minimum 8 characters is required.'),
});

export const registerOtpValidation = yup.object().shape({
  emailVerificationCode: yup
    .string()
    .required("Please Enter Verification Code")
    .min(6, "Minimum 6 characters is required.")
    .max(6, "Maximum 6 characters is required.")
});

export const growthCal = yup.object().shape({
  fraction: yup.string().required("Fraction number is required")
});
export const currencyValidation = yup.object().shape({
  currency: yup.string().required("Required"),
  currentRate: yup.string().required("Required"),
  agentCommission: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  enhancer: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required"),
  platformFee: yup
    .number()
    .positive()
    .transform((value) => (isNaN(value) ? undefined : value))
    .required("Required")
});
export const userValidation = yup.object({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    ),
  city: yup.string().nullable(),
  country: yup.string().nullable(),
  whatsapp: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    )
    .nullable(),
  telegram: yup.string().nullable()
});
export const partnerValidation = yup.object({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    ),
  city: yup.string().required("City is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  timeZone: yup.string().required("TimeZone is required"),
  whatsapp: yup
    .string()
    .matches(
      /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
      "Please enter only 10 digit number."
    )
    .nullable(),
  telegram: yup.string().required("Telegram is required")
});
export const QrCodeValidation = yup.object().shape({
  upi: yup.string().required("Required"),
  image: yup.string().required("Required")
});

export const AssignPartnerValidation = yup.object().shape({
  partnerId: yup.string().required("Required")
});

export const AccountValidation = yup.object().shape({
  accountHolderName: yup.string().required("Required"),
  accountNumber: yup
    .string()
    .matches(/^[0-9]{9,18}$/, "Invalid account number")
    .required("Required"),
  bankName: yup.string().required("Required"),
  ifscCode: yup
    .string()
    .matches(/^[A-Za-z]{4}[0-9]{7}$/, "Invalid IFSC code")
    .required("Required")
});
export const addAccountValidation = yup.object().shape({
  accountHolderName: yup.string().required("Account holder name is required"),
  bankName: yup.string().required("Required"),
  accountNumber: yup
    .string()
    .matches(/^[0-9]{9,18}$/, "Invalid account number")
    .required("Account number is required"),
  confirmAccountNumber: yup
    .string()
    .oneOf([yup.ref("accountNumber"), null], "Account number must match")
    .matches(/^[0-9]{9,18}$/, "Invalid account number")
    .required("Confirm Account number is required"),
  ifscCode: yup
    .string()
    .matches(/^[A-Za-z]{4}[A-Za-z0-9]{7}$/, "Invalid IFSC code")
    .required("Ifsc code is required")
});

export const withdrawAmountValidation = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .typeError("Amount must be a number")
    .min(1000, "Amount must be at least 1000")
    .max(1000000, "Amount must not exceed 1,000,000")
});
export const merchantValidationSchema = yup.object().shape({
  fullname: yup.string().required("Full name is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    ),
  websiteDomain: yup.string().required("Website Domain is required"),
  platformName: yup.string().required("Platform Name is required"),
  callbackUrl: yup.string().required("Call Back Url is required"),
  uin: yup.string().required("uin is required"),
  walletAddress: yup.string().required("Wallet Address is required"),

  howToFind: yup.string().required("How to find is required")
});
export const loginValidation = yup.object().shape({
  emailOrMobile: yup.string().required("Mobile number is required"),

  password: yup.string().required("Please enter password.")
});

export const forgotPasswordValidation = yup.object().shape({
  mobile: yup.string().required("Mobile number is required")

  // otp: yup.string().required('Please enter otp.'),
  // password: yup.string().required('Please enter password.'),
});
export const resetPassword = yup.object().shape({
  mobile: yup.string().required("Mobile number is required"),
  code: yup.string().required("Please enter otp."),
  newPassword: yup.string().required("Please enter new password.")
});
export const kycValidation = yup.object().shape({
  aadharNumber: yup
    .string()
    .required("Aadhar number is required")
    .matches(
      /^\d{12}$/,
      "Aadhar number must be exactly 12 digits long and contain only numbers"
    ),
  mobileNumber: yup
    .string()
    .matches(/^\+91/, "Mobile number must start with +91")
    .matches(
      /^\+91\d{10}$/,
      "Mobile number must have exactly 10 digits after +91"
    )
    .required("Mobile number is required")
  // captcha: yup.string().required("Please Enter Captcha")
  // otp: yup.string().required('Please Enter OTP'),
});
export const panCardValidation = yup.object().shape({
  panNumber: yup.string().required("Please Enter Pan Number")
});
export const otpValidation = yup.object().shape({
  otp: yup.string().required("Please Enter otp")
});
export const mobileVerificationValidation = yup.object().shape({
  mobile_verification_code: yup
    .string()
    .required("Please Enter Mobile Verification Code")
});
export const walletAddress = yup.object().shape({
  walletAddress: yup
    .string()
    .required("Please Enter Wallet Address")
    .matches(
      /^(0x)?[0-9a-fA-F]{40}$/,
      'Wallet address must be a valid hexadecimal string with or without a "0x" prefix.'
    )
});
export const walletAddressWithOtp = yup.object().shape({
  walletAddress: yup
    .string()
    .required("Please Enter Wallet Address")
    .matches(
      /^(0x)?[0-9a-fA-F]{40}$/,
      'Wallet address must be a valid hexadecimal string with or without a "0x" prefix.'
    ),
  mobileVerificationOtp: yup
    .string()
    .required("Please Enter OTP")
    .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
});
export const walletEmail = yup.object().shape({
  email: yup
    .string()
    .required("Please enter you email address")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter valid email"
    )
});

export const nameValidation = yup.object().shape({
  firstname: yup.string().required("First name is required"),
  lastname: yup.string().required("Last name is required")
});

export const nodesPurchaseValidation = yup.object().shape({
  numberOfFractionToBuy: yup
    .number()
    .typeError("Please Enter No of Fraction To Buy")
    .required("Please Enter No of Fraction To Buy")
    .min(1, "The number must be greater than zero")
});
export const fractionManagement = yup.object().shape({
  userLevel: yup.string().required("Please Select User Level"),
  percentage: yup
    .number()
    .required("Please Enter Percentage")
    .min(1, "The number must be greater than zero")
});
export const addPurchaseValidation = yup.object().shape({
  purchaseDate: yup.string().required("Please Select Purchase Date"),
  numberOfFractionToBuy: yup
    .string()
    .required("Please Enter Fraction")
    .min(1, "The number must be greater than zero")
    .max(100, "The number must be smaller than 100"),
  paidAmount: yup.string().required("Please Enter Amount"),
  accountNumber: yup
    .string()
    .required("Please Enter Account Number")
    .min(10, "Account Number must be at least 10 digits")
    .max(17, "Account Number cannot exceed 16 digits"),
  bankName: yup.string().required("Please Enter Bank Name"),
  branch: yup.string().required("Please Enter Branch Name"),
  paymentScreenshot: yup.string().required("Please Upload Payment Screenshot"),
  transactionId: yup.string().required("Please Enter UTR ID"),
  walletAddress: yup
    .string()
    .required("Please Enter Wallet Address")
    .matches(
      /^(0x)?[0-9a-fA-F]{40}$/,
      'Wallet address must be a valid hexadecimal string with or without a "0x" prefix.'
    )
});
// purchaseDate: '',
// numberOfFractionToBuy: '',
// paidAmount: '',
// accountNumber: '',
// bankName: '',
// branch: '',
// paymentScreenshot: '',
// walletAddress: '',
