import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { reactIcons } from "../../utils/icons";
import Cookies from "js-cookie";
import { useSelector } from "react-redux";
import { getAuthReq } from "../../utils/apiHandlers";
import toast from "react-hot-toast";

const Header = ({ toggleSidebar, isVerify }) => {
  const navigate = useNavigate();
  const [NotificationData, setNotificationData] = useState();
  const { userData } = useSelector((state) => state?.user || {});
  const [showNotification, setShownotification] = useState(
    Boolean(Cookies.get(`NotificationShow`))
  );
  const currentPath = window.location.pathname;
  console.log(window.location.pathname, "window.location.pathname");
  const getToken = Cookies.get(`accessToken`);
  const getNotification = async () => {
    try {
      const res = await getAuthReq(
        "/notification/all-active-alert-notification"
      );
      const { data, status } = res;
      if (status) {
        const grouped = data.reduce(
          (acc, item) => {
            if (item.display === "BeforeLogin") {
              acc.BeforeLogin.push(item);
            } else if (item.display === "AfterLogin") {
              acc.AfterLogin.push(item);
            }
            return acc;
          },
          { BeforeLogin: [], AfterLogin: [] }
        );
        setNotificationData(grouped);
      } else {
        toast.error(data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  console.log(NotificationData, "NotificationData");

  useEffect(() => {
    getNotification();
  }, []);

  const handleCookiesForShow = () => {
    Cookies.set("NotificationShow", true);
    setTimeout(() => {
      Cookies.remove("NotificationShow");
      setShownotification(Boolean(Cookies.get(`NotificationShow`)));
    }, 1800000);
    setShownotification(Boolean(Cookies.get(`NotificationShow`)));
  };

  return (
    <>
      {/* {!showNotification &&
        Boolean(getToken) &&
        NotificationData?.AfterLogin.length >= 1 && (
          <div className="h-[40px] relative w-full bg-black border-b text-white flex items-center border-white">
            <marquee behavior="scroll" direction="left" scrollamount="8">
              <div className="flex gap-5">
                {NotificationData?.AfterLogin.map((items, index) => {
                  return (
                    <>
                      {items?.displayPage == currentPath ||
                      items?.displayPage == "/all" ? (
                        <span
                          key={index}
                          className={
                            items?.type == "Info"
                              ? "text-green-500"
                              : "text-[#ed0101]"
                          }
                        >
                          <span className="font-semibold">{items.title}</span> :{" "}
                          {items.description}
                        </span>
                      ) : (
                        ""
                      )}
                    </>
                  );
                })}
              </div>
            </marquee>
            <div
              onClick={handleCookiesForShow}
              className="absolute bg-black p-2 right-0 flex justify-center items-center"
            >
              {reactIcons.close}
            </div>
          </div>
        )} */}
      {!showNotification &&
      Boolean(getToken) &&
      NotificationData?.AfterLogin.some(
        (item) =>
          (item.displayPage === "/all" && item.displayPage == currentPath) ||
          (item.displayPage == currentPath && item.displayPage != "/all") ||
          item.displayPage == "/all"
      ) ? (
        <div className="h-[40px] relative w-full bg-black border-b text-white flex items-center border-white">
          <marquee behavior="scroll" direction="left" scrollamount="8">
            <div className="flex gap-5">
              {NotificationData?.AfterLogin.map((items, index) => (
                <>
                  {/* <span
                  key={index}
                  className={
                    items?.type === "Info" ? "text-green-500" : "text-[#ed0101]"
                  }
                >
                  <span className="font-semibold">{items.title}</span> :{" "}
                  {items.description}
                </span> */}
                  {items?.displayPage == currentPath ||
                  items?.displayPage == "/all" ? (
                    <span
                      key={index}
                      className={
                        items?.type == "Info"
                          ? "text-green-500"
                          : "text-[#ed0101]"
                      }
                    >
                      <span className="font-semibold">{items.title}</span> :{" "}
                      {items.description}
                    </span>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </marquee>
          <div
            onClick={handleCookiesForShow}
            className="absolute bg-black p-2 right-0 flex justify-center items-center"
          >
            {reactIcons.close}
          </div>
        </div>
      ) : (
        ""
      )}

      {!showNotification &&
        !Boolean(getToken) &&
        NotificationData?.BeforeLogin.length >= 1 && (
          <div className="h-[40px] relative w-full bg-black border-b text-white flex items-center border-white">
            <marquee behavior="scroll" direction="left" scrollamount="8">
              <div className="flex gap-5 lg:gap-10">
                {NotificationData?.BeforeLogin.map((items, index) => {
                  return (
                    <>
                      {items?.displayPage == "/all" && (
                        <span
                          key={index}
                          className={
                            items?.type == "Info"
                              ? "text-green-500"
                              : "text-[#ed0101]"
                          }
                        >
                          <span className="font-semibold">{items.title}</span> :{" "}
                          {items.description}
                        </span>
                      )}
                    </>
                  );
                })}
              </div>
            </marquee>
            <div
              onClick={handleCookiesForShow}
              className="absolute bg-black p-2 right-0 flex justify-center items-center"
            >
              {reactIcons.close}
            </div>
          </div>
        )}
      <div className="h-[80px] w-full z-[9999] bg-black border-b border-[#FFE3E3]">
        <div className="flex lg:items-center justify-between gap-3 cursor-pointer px-3 py-3">
          <div
            onClick={() => {
              const cookeiData = Cookies.get(`accessToken`);
              if (cookeiData && !isVerify) {
                navigate("/kyc");
              } else if (cookeiData) {
                navigate("/dashboard");
              } else {
                navigate("/");
              }
            }}
            className="flex items-center gap-2"
          >
            <div>
              <img
                src="/images/logo/main.png"
                alt="logo"
                className="w-[55px]"
              />
            </div>

            <div>
              <img
                src="/images/logo/sad.png"
                alt="logo"
                className="w-[180px]"
              />
              <p className="text-[8px] text-white mt-1  text-center">
                YOUR PATH TO A DECENTRALIZED FUTURE
              </p>
            </div>
          </div>

          {getToken && (
            <div className="hidden lg:flex gap-4">
              {/* <button
                type="button"
                onClick={() =>
                  window.open("https://support.mstvalidator.com/", "_blank")
                }
                className="text-white py-2 flex items-center justify-center px-2 bg-gradient-red rounded-md gap-1 font-normal border border-[#EE0000]  md:w-[195px]"
              >
                <span className="text-24">{reactIcons?.support}</span>{" "}
                <span className="text-[15px]">Customer Support</span>
              </button> */}
              <div
                className="hidden lg:flex items-center"
                onClick={() => navigate("/profile")}
              >
                <span className="bg-white p-2 rounded-full mr-2">
                  {reactIcons.user}
                </span>
                <div>
                  <p className="text-white capitalize mr-2 font-semibold">
                    {userData?.firstname + " " + userData?.lastname || "N/A"}{" "}
                  </p>
                  <p className="text-red-500 mr-2 text-12 font-semibold">
                    {userData?.meta?.referredAs == "PowerFranchise"
                      ? "Power Franchise"
                      : userData?.meta?.referredAs == "Franchise" &&
                        userData?.uplineFranchiseCount >= 2
                      ? "Distributor"
                      : userData?.meta?.referredAs == "User"
                      ? ""
                      : userData?.meta?.referredAs || "N/A"}{" "}
                  </p>
                </div>
              </div>
            </div>
          )}
          {/* {getToken && (
          <div className="flex items-center">
            <p className="text-white mr-2">Referral Link: </p>
            <CopyToClipboard text={link} onCopy={handleCopyLink}>
              <span className="border border-gray-300 rounded-md p-2 gap-2 cursor-pointer flex items-center text-white">
                {link.slice(0, 35) + '...' || 'N/A'}
                {reactIcons.copy}
              </span>
            </CopyToClipboard>
          </div>
        )} */}
          <div className="pr-1 sm:flex gap-3 lg:hidden">
            {/* {getToken && (
              <button
                onClick={() =>
                  window.open("https://support.mstvalidator.com/", "_blank")
                }
                className="w-10 h-10 flex-center mt-1 text-white bg-common rounded-full text-3xl"
              >
                {reactIcons.support}
              </button>
            )} */}
            <button
              onClick={toggleSidebar}
              className="w-10 h-10 flex-center mt-1 text-white bg-common rounded-full text-3xl"
            >
              {reactIcons.toggle}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
