import React, { useEffect, useState } from "react";

import DateRangePickerCustom from "../../components/Forms/DateRangePickerCustom";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import Card from "../../components/Forms/Card";
import { getAuthReq } from "../../utils/apiHandlers";
import MainTable from "./child/MainTable";
import { reactIcons } from "../../utils/icons";
import Spinner from "../../components/loaders/Spinner";
import { useSelector } from "react-redux";

const Report = () => {
  const take = 5;
  const { userData } = useSelector((state) => state?.user || {});
  const [typeOfNodeId, setTypeOfNodeId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [typeOfReport, setTypeOfReport] = useState("genesis");
  const [pageNumber, setPageNumber] = useState(1);
  const [dashboardData, setDashboardData] = useState({});
  const [listData, setListData] = useState({
    count: 0,
    data: []
  });
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate());
  const handlePage = (e, page) => {
    e.preventDefault();
    setPageNumber(page);
  };
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const headings = [
    "Sr. no",
    "Node ID",
    "Date",
    "Total Validated Block",
    "Hash Count",
    "View"
  ];
  const handleNavigate = (item, idx) => {
    navigate(`/report/${idx}`, {
      state: {
        ...item
      }
    });
  };

  const fetchDashboardReport = async (userData) => {
    setIsLoading(true);
    const res = await getAuthReq(
      `/reports/dashboard?userId=${userData?.id}&stageId=1`
    );
    const { status, data } = res;
    if (status) {
      setIsLoading(false);
      setDashboardData(data);
    }
  };

  const fetchAllReport = async (skip, take, nodeID) => {
    setIsLoading(true);
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");
    let nodeIdParam = "";
    if (nodeID) {
      nodeIdParam = `&nodeId=${nodeID}`;
    }
    const res = await getAuthReq(
      `/reports/all?skip=${skip}&take=${take}&startDate=${start}&endDate=${end}${
        nodeIdParam && nodeIdParam
      }`
    );
    const { status, data } = res;
    if (status) {
      setListData({ count: data.count, data: data.data });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      fetchDashboardReport(userData);
    }
  }, [userData]);
  useEffect(() => {
    fetchAllReport((pageNumber - 1) * take, take);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, take, startDate, endDate]);

  return (
    <>
      {/* <ComingSoon /> */}
      {isLoading && <Spinner />}

      <div className="h-[100%]">
        <div className="flex items-center justify-between mb-3 md:mb-10">
          <h4 className="cursor-pointer text-white text-start md:text-center text-16 md:text-[24px] lg:text-[24px]">
            Report
          </h4>
        </div>

        <div>
          <Card data={dashboardData} userData={userData} />
        </div>
        <div className="flex flex-col lg:flex-row lg:items-center gap-4 justify-end w-full xl:w-auto mb-5 mt-10">
          <p className="text-white text-sm sm:text-base md:text-lg lg:text-16">
            Date
          </p>

          <div className="w-full lg:w-[273px] border border-[#EE0000] rounded-lg">
            <DateRangePickerCustom
              onChange={onChange}
              startDate={startDate}
              endDate={endDate}
            />
          </div>

          <div className="relative w-full lg:w-auto min-w-[150px] sm:min-w-[180px] md:min-w-[200px]">
            <span className="absolute top-1/2 transform -translate-y-1/2 right-4 text-white pointer-events-none">
              {reactIcons.arrowDown}
            </span>
            <select
              className="w-full text-sm sm:text-base md:text-16 h-[42px] border !border-[#EE0000] bg-transparent text-white pr-10"
              name="typeOfOrder"
              id="typeOfOrder"
              value={typeOfReport}
              onChange={(e) => setTypeOfReport(e.target.value)}
            >
              {[
                { label: "Genesis", value: "genesis" },
                { label: "Alchemy", value: "alchemy" },
                { label: "Nexus", value: "nexus" },
                { label: "Vertex", value: "vertex" },
                { label: "Nirvana", value: "nirvana" }
              ].map((item, index) => (
                <option
                  key={index}
                  value={item.value}
                  disabled={item.value === "genesis" ? false : true}
                  className="bg-[#0F0F0F] text-white"
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>

          <div className="relative w-full lg:w-auto min-w-[150px] sm:min-w-[180px] md:min-w-[200px]">
            <span className="absolute top-1/2 transform -translate-y-1/2 right-4 text-white pointer-events-none">
              {reactIcons.arrowDown}
            </span>
            <select
              className="w-full text-sm sm:text-base md:text-16 h-[40px] border !border-[#EE0000] bg-transparent text-white pr-10"
              name="typeOfNode"
              id="typeOfNode"
              value={typeOfNodeId}
              onChange={(e) => {
                fetchAllReport((pageNumber - 1) * take, take, e.target.value);
                setTypeOfNodeId(e.target.value);
              }}
            >
              {[
                { label: "Node ID", value: "" },
                { label: "1", value: "1" },
                { label: "2", value: "2" }
              ].map((item, index) => (
                <option
                  key={index}
                  value={item.value}
                  className="bg-[#0F0F0F] text-white"
                >
                  {item.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="">
          <MainTable
            headings={headings}
            handleNavigate={handleNavigate}
            pageNumber={pageNumber}
            listData={listData}
            handlePage={handlePage}
            take={take}
            setPageNumber={setPageNumber}
          />
        </div>
      </div>
    </>
  );
};
export default Report;
