import React, { useEffect, useState } from "react";
import DateRangePickerCustom from "../Forms/DateRangePickerCustom";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getAuthReq } from "../../utils/apiHandlers";
import { reactIcons } from "../../utils/icons";
import Spinner from "../loaders/Spinner";
import EmailLogsTable from "./EmailLogsTable";
import NameLogsTable from "./NameLogsTable";
const headings = ["Sr. no", "Email", "Mobile", "Date", "Status"];
const NameLogs = ({ triggerApi, handleNameLog }) => {
  const take = 5;
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").toDate()
  );
  const [endDate, setEndDate] = useState(moment().endOf("month").toDate());
  const [listData, setListData] = useState({
    count: 0,
    data: []
  });
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const handleNavigate = (item, idx) => {
    navigate(`/report/${idx}`, {
      state: {
        ...item
      }
    });
  };
  const handlePage = (e, page) => {
    e.preventDefault();
    setPageNumber(page);
  };
  const fetchAllWithdraw = async (skip, take) => {
    setIsLoading(true);
    let start = moment(startDate).format("YYYY-MM-DD");
    let end = moment(endDate).format("YYYY-MM-DD");

    const res = await getAuthReq(
      `/users/me/email-update-log?skip=${skip}&take=${take}&startDate=${start}&endDate=${end}&type=name`
    );
    const { status, data } = res;
    if (status) {
      setListData({ count: data.count, data: data.data });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllWithdraw((pageNumber - 1) * take, take);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, take, startDate, endDate, triggerApi]);
  return (
    <>
      {isLoading && <Spinner />}
      <div className="flex flex-col lg:flex-row lg:items-center  justify-between w-full xl:w-auto mb-5 mt-10">
        <div>
          <h4 className="cursor-pointer text-white text-start md:text-center text-16 md:text-[24px] lg:text-[24px] mb-4 md:mb-0 flex items-center gap-2">
            <button
              onClick={handleNameLog}
              className="btn-gradient w-8 h-8 border border-white rounded-full flex items-center justify-center text-14"
              type="button"
            >
              {reactIcons.arrowleft}
            </button>
            Username Logs
          </h4>
        </div>
        <div className="flex flex-col md:flex-row gap-4 items-center">
          <div className="flex items-center w-full gap-2 md:w-auto">
            <p className="text-white text-sm sm:text-base md:text-lg lg:text-16">
              Date
            </p>

            <div className="w-full lg:w-[273px] border border-[#EE0000] rounded-lg">
              <DateRangePickerCustom
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <NameLogsTable
          headings={headings}
          handleNavigate={handleNavigate}
          pageNumber={pageNumber}
          listData={listData}
          handlePage={handlePage}
          take={take}
          setPageNumber={setPageNumber}
        />
      </div>
    </>
  );
};

export default NameLogs;
