// Import necessary modules from Firebase v9+ (modular SDK)
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken
} from "firebase/messaging";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBLTCL1_VxaH5VIeDWU7bwiCE2l34WFHGg",
  authDomain: "mst-blockchain.firebaseapp.com",
  projectId: "mst-blockchain",
  storageBucket: "mst-blockchain.firebasestorage.app",
  messagingSenderId: "80601475135",
  appId: "1:80601475135:web:a28044053880f9e6c8f08a",
  measurementId: "G-P87FKE9J6D"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const publicKey = process.env.REACT_APP_VAPID_KEY;

console.log(publicKey, "publicKey");

export const getFCMToken = async (setTokenFound) => {
  let currentToken = "";
  try {
    currentToken = await getToken(messaging, { vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token.", error);
  }
  return currentToken;
};

export const onMessageListener = (setNotification) =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        messageId: payload.messageId
      });
      resolve(payload);
    });
  });

export { messaging, getToken, deleteToken };
