import { useEffect } from "react";
import toast from "react-hot-toast";

const NotificationComponent = ({ notification, show }) => {
  useEffect(() => {
    if (notification?.title && show) {
      toast.custom(
        (t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
            style={{
              background:
                "linear-gradient(292deg, #ff0000 0%, #000000 75%, #000000 100%)",
              border: "1px solid #ff0000"
            }}
          >
            <div className="flex-1 w-0 p-3 lg:p-4">
              <div className="flex items-start">
                <div>
                  <img
                    src="/images/logo/main.png"
                    alt="logo"
                    className="w-[35px] mt-1 lg:mt-0 lg:w-[55px]"
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm capitalize font-medium text-gray-500">
                    {notification?.title}
                  </p>
                  <p className="mt-1 text-[12px] text-white">
                    {notification?.body}
                  </p>
                </div>
              </div>
            </div>
            <div onClick={() => toast.dismiss(t.id)} className="flex">
              <button className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-white hover:text-white focus:outline-none">
                &#10005;
              </button>
            </div>
          </div>
        ),
        { duration: 5000 }
      );
    }
  }, [notification, show]);

  return null;
};

export default NotificationComponent;
