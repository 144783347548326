import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddressModal from "../modals/AddressModal";

import { toast } from "react-toastify";
import { patchReq, postAuthReq } from "../../utils/apiHandlers";
import { fetchUserData } from "../../redux/Slice/User/user";
import { isYupError, parseYupError } from "../../utils/Yup";
import { walletAddress, walletAddressWithOtp } from "../../utils/validation";
import UpdateAddressModal from "../modals/UpdateAddressModal";
import { reactIcons } from "../../utils/icons";
const initialState = {
  walletAddress: "",
  mobileVerificationOtp: ""
};
const WalletAddUpdate = ({ handleAddressLogs }) => {
  const { userData } = useSelector((state) => state?.user || {});
  const [open, setOpen] = useState(false);
  const [otpOpen, setOtpOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialState);
  const [formError, setFormError] = useState(initialState);

  // input value update function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  };

  // add wallet address function
  const addWalletAddress = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await walletAddress.validate(form, {
        abortEarly: false
      });
      let payload = {
        walletAddress: form.walletAddress
      };
      const res = await patchReq("/users/me", payload);
      const { status, data } = res;
      if (status) {
        toast.success(data.status);
        setIsOpen(false);
        dispatch(fetchUserData());
        setForm(initialState);
        setFormError(initialState);
      } else {
        if (Array.isArray(res?.error?.message)) {
          toast.error(res?.error?.message[0]);
        } else {
          toast.error(res?.error?.message);
        }
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  // update wallet address function
  const hanldeUpdateWallet = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await walletAddressWithOtp.validate(form, {
        abortEarly: false
      });
      const res = await postAuthReq("/wallet-address/update-request", form);
      const { status, error } = res;
      if (status) {
        setIsLoading(false);
        setOpen(false);
        setOtpOpen(false);
        toast.success("Request Created Successfully");
        setForm(initialState);
        setFormError(initialState);
      } else {
        toast.error(error?.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
        setIsLoading(false);
      } else {
        toast.error(error);
        setIsLoading(false);
      }
    }
  };

  // update wallet address function
  const hanldeSendCode = async () => {
    if (isLoading) return;
    try {
      setIsLoading(true);
      await walletAddress.validate(form, {
        abortEarly: false
      });
      const payload = {
        mobile: userData?.mobile,
        type: "update_wallet",
        country: userData?.country
      };
      const res = await postAuthReq("/auth/send-code", payload);
      const { status, error } = res;
      if (status) {
        setIsLoading(false);
        setOtpOpen(true);
        toast.success("OTP Send Successfully");
      } else {
        toast.error(error?.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (isYupError(error)) {
        setFormError(parseYupError(error));
        setIsLoading(false);
      } else {
        toast.error(error);
        setIsLoading(false);
      }
    }
  };
  return (
    <>
      {userData?.meta?.walletAddress !== null ? (
        <>
          <button
            type="button"
            onClick={() => setOpen(true)}
            className="gradient-background text-white p-2.5 rounded-lg items-center justify-center gap-2 border text-22 font-normal absolute top-[2px] right-[2px] bottom-[2px] h-[46px] w-[46px] hidden"
          >
            {reactIcons.edit}
          </button>
          <button
            type="button"
            onClick={handleAddressLogs}
            className="w-full text-right text-[11px] text-[#ee0000] underline mt-2"
          >
            View Wallet Address Logs
          </button>
        </>
      ) : (
        <button
          type="button"
          onClick={() => setIsOpen(true)}
          className="gradient-background text-white p-2.5 rounded-lg flex items-center justify-center gap-2 border text-22 font-normal absolute top-[2px] right-[2px] bottom-[2px] h-[46px] w-[46px]"
        >
          {reactIcons.add}
        </button>
      )}
      <AddressModal
        setOpen={setIsOpen}
        open={isOpen}
        isLoading={isLoading}
        formError={formError}
        handleChange={handleChange}
        addWalletAddress={addWalletAddress}
      />
      <UpdateAddressModal
        setOpen={setOpen}
        open={open}
        otpOpen={otpOpen}
        setOtpOpen={setOtpOpen}
        isLoading={isLoading}
        formError={formError}
        handleChange={handleChange}
        hanldeUpdateWallet={hanldeUpdateWallet}
        hanldeSendCode={hanldeSendCode}
      />
    </>
  );
};

export default WalletAddUpdate;
